<template>
    <div>
        <div class="title">
              <h2>{{ $t('menu.limpar') }}</h2>
            </div>
        <Nota :texto="$t('limpar.nota')" />
        <textarea v-model="text" cols="30" rows="5" v-if="!result.length" />
        <br />

        <div style="display: flex;justify-content: space-between;">
            <vue-flip v-model="active" active-click height="50px" width="100%" class="btn-container">
                <template v-slot:front>
                    <div class="btn" @click="setListConvert">
                        {{ $t('limpar.converter') }}
                    </div>
                </template>
                <template v-slot:back>
                    <div class="btn" @click.prevent="getEmpty('initial')">
                        {{ $t('limpar.limpar') }}
                    </div>
                </template>
            </vue-flip>
            <div class="flipper btn-container">
                <a class="btn btn-down" href="#" @click.prevent="checkClipboard">
                    <img class="paste" src="../assets/paste_icon.png" />
                </a>
            </div>
            <!-- <div class="flipper btn-container" v-if="result.length">
        <a class="btn btn-down" href="#" @click.prevent="createAndOpenFile"
          >{{$t('limpar.downgpx')}}</a
        >
      </div> -->
            <vue-flip v-model="activeMapa" active-click height="50px" width="100%" class="btn-container"
                v-if="result.length > 1">
                <template v-slot:front>
                    <div class="btn" @click="verAndOpenFile">{{ $t('limpar.mapa') }}</div>
                </template>
                <template v-slot:back>
                    <div class="btn" @click="gpx = ''">{{ $t('limpar.mapa') }}</div>
                </template>
            </vue-flip>
            <vue-flip active-click height="50px" width="100%" class="btn-container" v-if="result.length > 1">
                <template v-slot:front>
                    <div class="btn" @click="setSaidaCopy">
                        {{ $t('missao.gerarLista') }}
                    </div>
                </template>
                <template v-slot:back>
                    <div class="btn">{{ $t('missao.gerarLista') }}</div>
                </template>
            </vue-flip>
        </div>
        <Mapa v-if="gpx" :gpx="gpx" style="margin-bottom: 15px" />

        <div class="btn btn-velocity" href="#" @click.prevent="selectVelocity" v-if="resultPage.length">
            {{
            velocity
            ? $t('missao.velocityAtivado')
            : $t('missao.velocityDesativado')
            }}
        </div>

        <ul class="home">
            <li v-for="(r, k) in resultPage" :key="k">
                <table>
                    <tr>
                        <td class="coord">
                            <span class="coord" :style="r.copy ? 'text-decoration: line-through;' : ''">{{ r.coord
                            }}</span>
                        </td>
                        <td class="tempo">
                            <span v-if="!r.copy">{{ r.tempo }}</span>
                            <span class="text-tempo" v-else>
                                <countdown :time="r.time * 1000" :transform="transform">
                                    <template slot-scope="props">
                                        {{ props.minutes }}:{{ props.seconds }}</template>
                                </countdown>
                            </span>
                        </td>
                        <td class="copy" v-if="!velocity">
                            <!-- <td class="copy" v-if="!velocity"> -->
                            <vue-flip active-click width="100px" height="45px" class="btn-content" v-model="r.copy">
                                <template v-slot:front>
                                    <div class="btn" @click="getCopy(r, r.id)">
                                        {{ $t('missao.copiar') }}
                                    </div>
                                </template>
                                <template v-slot:back>
                                    <div class="btn" @click="getCopy(r, r.id)">=)</div>
                                </template>
                            </vue-flip>
                        </td>
                        <td v-else>
                            <!--  <td v-else> -->
                            <vue-flip active-click width="100px" height="45px" class="btn-content" v-model="r.copy">
                                <template v-slot:front>
                                    <div class="btn" @click="getCopyVelocity(r, r.id)">
                                        {{ $t('missao.Abrir') }}
                                    </div>
                                </template>
                                <template v-slot:back>
                                    <div class="btn" @click="getCopyVelocity(r, r.id)">
                                        =)
                                    </div>
                                </template>
                            </vue-flip>
                        </td>
                    </tr>
                </table>

                <hr v-if="k % 3 === 2" />
            </li>
        </ul>
        <vue-ads-pagination :total-items="result.length" :max-visible-pages="3" :page="page" :items-per-page="perpage"
            @page-change="pageChange">
            <template slot-scope="props" v-if="true">
                <div class="vue-ads-pr-2 vue-ads-leading-loose">
                    {{ props.start }}
                </div>
            </template>
            <template slot="buttons" slot-scope="props">
                <vue-ads-page-button v-for="(button, key) in props.buttons" :key="key" v-bind="button"
                    @page-change="pageChange(button)" />
            </template>
        </vue-ads-pagination>
    </div>
</template>

<script>
import axios from 'axios'
import VueFlip from 'vue-flip'
import Countdown from '@chenfengyuan/vue-countdown'
import '@fortawesome/fontawesome-free/css/all.css'
import 'vue-ads-pagination/dist/vue-ads-pagination.css'
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination'
import { Clipboard } from '@capacitor/clipboard'
import Mapa from '../components/Mapa'
import { getAnalytics, logEvent } from 'firebase/analytics'
import Nota from '../components/Nota'

export default {
    name: 'App',
    components: {
        Countdown,
        VueFlip,
        VueAdsPagination,
        VueAdsPageButton,
        Mapa,
        Nota
    },
    computed: {
        resultPage() {
            return this.result.slice(
                this.page * this.perpage,
                this.page * this.perpage + this.perpage
            )
        }
    },
    data() {
        return {
            text: '',
            result: '',
            results: [],
            content: '',
            saida: '',
            list: [],
            select: '',
            loading: false,
            page: 0,
            total: 0,
            perpage: 21,
            active: false,
            activeMapa: false,
            gpx: '',
            velocity: false
        }
    },
    mounted() {
        const analytics = getAnalytics(this.$fire)
        logEvent(analytics, 'screen_view', {
            firebase_screen: `APP - ${this.$route.meta.lang} ${this.$route.meta.titulo}`,
            firebase_screen_class: 'Limpar'
        })
        document.title = `APP CoordsGo.com - ${this.$t('menu.limpar')}`
        if (localStorage.getItem('_result_limpar')) {
            this.active = true
            this.result = JSON.parse(localStorage.getItem('_result_limpar')).map(
                i => {
                    if (i.copy) {
                        i.time = 0
                    }
                    return i
                }
            )
        }
        this.getListCoords(1)
        this.checkClipboard()
        if (this.$route.params.id) {
            this.getListCoordsRouter(this.$route.params.id)
        }
        if (localStorage.getItem('_velocityLimpar')?.length) {
            this.velocity =
                localStorage.getItem('_velocityLimpar') === 'true' ? true : false
        } else {
            this.velocity = false
        }
    },
    methods: {
        async checkClipboard() {
            const { type, value } = await Clipboard.read()
            console.log(`Got ${type} from clipboard: ${value}`)
            this.text = value
        },
        setSaidaCopy() {
            this.$copyText(this.saida).then(
                () => null,
                () => alert('Erro')
            )
        },
        selectVelocity() {
            localStorage.setItem('_velocityLimpar', !this.velocity)
            this.velocity = !this.velocity
        },
        getCopyVelocity(r, key) {
            //r.copy = !r.copy

            const cache = JSON.parse(localStorage.getItem('_result_limpar'))
            cache.map(i => {
                if (i.id === key) {
                    i.copy = !r.copy
                }
                return i
            })
            localStorage.setItem('_result_limpar', JSON.stringify(cache))
            if (!r.copy) {
                window.open(`https://pk.md/${r.coord}`, '_blank')
            }
        },
        getListUpdate() {
            this.page = 0
            this.getListCoords(1)
        },
        async getListCoords(page) {
            this.loading = true
            const list = await axios(`${this.$url}/missoes`, {
                params: {
                    page
                }
            })
            this.list = list.data.data
            this.total = list.data.total
            this.loading = false
        },
        pageChange(page) {
            //this.page = page
            this.page = page.page
            this.getListCoords(page.page + 1)
        },

        getCopy(r, key) {
            //r.copy = !r.copy
            const cache = JSON.parse(localStorage.getItem('_result_limpar'))
            cache.map((i, k) => {
                if (k === key) {
                    i.copy = !r.copy
                }
                return i
            })
            localStorage.setItem('_result_limpar', JSON.stringify(cache))
            if (!r.copy) {
                this.$copyText(r.coord).then(
                    () => null,
                    () => alert('Erro')
                )
            }
        },
        transform(props) {
            Object.entries(props).forEach(([key, value]) => {
                const digits = value < 10 ? `0${value}` : value
                props[key] = `${digits}`
            })

            return props
        },
        convert(num, text) {
            switch (text) {
                case 'seg':
                    return num
                case 'sec':
                    return num
                case 'min':
                    return num * 60
                default:
                    return num
            }
        },
        getEmpty(e = '') {
            this.text = ''
            this.select = ''
            this.result = []
            this.gpx = ''
            this.activeMapa = false
            if (e === 'initial') {
                this.active = true
            } else {
                this.active = false
            }
            if (localStorage.getItem('_result_limpar')) {
                localStorage.removeItem('_result_limpar')
            }
        },
        verAndOpenFile() {
            this.gpx = `<?xml version="1.0" encoding="UTF-8"?>
            <gpx version="1.1" creator="Só Coordenadas | Solo coordenadas | Only Coordinate | https://coordsgo.com">
              <trk>
              ${this.result
                    .map(i => {
                        let coord = i.coord.trim().split(',')
                        return `<trkpt lat="${coord[0]}" lon="${coord[1]}">
                  </trkpt>`
                    })
                    .join('')}</trk></gpx>`
        },
        createAndOpenFile() {
            let gpx = `<?xml version="1.0" encoding="UTF-8"?>
            <gpx version="1.1" creator="Só Coordenadas | Solo coordenadas | Only Coordinate | https://coordsgo.com">
              ${this.result
                    .map(i => {
                        let coord = i.coord.trim().split(',')
                        return `<wpt lat="${coord[0]}" lon="${coord[1]}"></wpt>`
                    })
                    .join('')}</gpx>`
            var a = window.document.createElement('a')
            a.href = window.URL.createObjectURL(
                new Blob([gpx], { type: 'text/json' })
            )
            a.download = `coordsgo.com_gpx${Math.floor(Date.now() / 1000)}.gpx`

            document.body.appendChild(a)
            a.click()

            document.body.removeChild(a)
        },
        async getListCoordsRouter(id) {
            const list = await axios.get(`${this.$url}/missoes/${id}`)
            this.setListCoordList(list.data)
        },
        rad(x) {
            return (x * Math.PI) / 180
        },

        calcularDistancia(lat1, lon1, lat2, lon2) {
            // double R = 6378.137;//Radio de la tierra en km
            var R = 6371.0
            var dLat = this.rad(lat2 - lat1)
            var dLong = this.rad(lon2 - lon1)

            var a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(this.rad(lat1)) *
                Math.cos(this.rad(lat2)) *
                Math.sin(dLong / 2) *
                Math.sin(dLong / 2)
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
            var d = R * c

            return d
        },
        tempo(dist) {
            var myTime = ''

            if (dist <= 0.01) {
                myTime = '0seg'
            } else if (dist <= 0.1) {
                myTime = '3seg'
            } else if (dist <= 0.5) {
                myTime = '15seg'
            } else if (dist <= 1) {
                myTime = '30seg'
            } else if (dist <= 2) {
                myTime = '1min'
            } else if (dist <= 5) {
                myTime = '2min'
            } else if (dist <= 6) {
                myTime = '4min'
            } else if (dist <= 7) {
                myTime = '5min'
            } else if (dist <= 8) {
                myTime = '6min'
            } else if (dist <= 10) {
                myTime = '7min'
            } else if (dist <= 12) {
                myTime = '8min'
            } else if (dist <= 14) {
                myTime = '9min'
            } else if (dist <= 18) {
                myTime = '10min'
            } else if (dist <= 21) {
                myTime = '13min'
            } else if (dist <= 26) {
                myTime = '15min'
            } else if (dist <= 42) {
                myTime = '19min'
            } else if (dist <= 65) {
                myTime = '22min'
            } else if (dist <= 70) {
                myTime = '24min'
            } else if (dist <= 81) {
                myTime = '25min'
            } else if (dist <= 100) {
                myTime = '35min'
            } else if (dist <= 220) {
                myTime = '40min'
            } else if (dist <= 250) {
                myTime = '45min'
            } else if (dist <= 350) {
                myTime = '51min'
            } else if (dist <= 375) {
                myTime = '54min'
            } else if (dist <= 460) {
                myTime = '1hor 2min'
            } else if (dist <= 500) {
                myTime = '1hor 5min'
            } else if (dist <= 565) {
                myTime = '1hor 9min'
            } else if (dist <= 700) {
                myTime = '1hor 18min'
            } else if (dist <= 800) {
                myTime = '1hor 24min'
            } else if (dist <= 900) {
                myTime = '1hor 32min'
            } else if (dist <= 1000) {
                myTime = '1hor 39min'
            } else if (dist <= 1100) {
                myTime = '1hor 47min'
            } else if (dist <= 1200) {
                myTime = '1hor 54min'
            } else if (dist <= 1300) {
                myTime = '1hor 57min'
            } else {
                myTime = '2hor'
            }
            return myTime
        },
        arendondar(num, dec) {
            return (+(Math.round(num + 'e+' + dec) + 'e-' + dec)).toFixed(dec)
        },
        async setListCoordList(list) {
            this.active = true
            this.getList(list.content)
            this.select = list.id
            await axios.put(`${this.$url}/missoes/${this.select}`, {
                click: Number(list.click) + 1
            })
            this.gpx = ''
            this.activeMapa = false
            document.body.scrollIntoView({ behavior: 'smooth', block: 'start' })
        },
        sendWaths(id) {
            window.open(
                `https://api.whatsapp.com/send?text=${window.encodeURIComponent(
                    `https://coordsgo.com/coordinates/${id}`
                )}`,
                '_blank'
            )
        },
        async setListCoord(list) {
            this.$router.push(`/coordinates/${list.id}`)
            this.active = true
            /*  this.getList(list.content)
            this.select = list.id
            await axios.put(
              `${this.$url}/missoes/${this.select}`,
              {
                click: Number(list.click) + 1
              }
            ) */
            this.gpx = ''
            this.activeMapa = false
            document.body.scrollIntoView({ behavior: 'smooth', block: 'start' })
        },
        async setListConvert() {
            this.select = ''
            this.gpx = ''
            this.activeMapa = false
            await axios.post(`${this.$url}/missoesold`, {
                text: this.text
            })
            this.getList(this.text)
        },
        async getList(text) {
            this.result = ''
            this.select = ''
            if (text) {
                const results = await text.matchAll(
                    // eslint-disable-next-line no-useless-escape
                    /(-?\d+(?![:|\/]).\d+, ?-?\d+(?![:|\/]).\d+)(:blank:)?(.+?(\d+\w+)?(\d+ ?)(\w{3}))?/g
                )
                this.result = Array.from(results).map(i => {
                    return {
                        coord: i[1],
                        copy: false,
                        tempo: i[5] ? `${i[5]}${i[6]}` : null,
                        time: i[5] ? Number(this.convert(i[5], i[6])) : 0
                    }
                })
                let dados = this.result.map(i => {
                    let coords = i.coord.split(',')
                    i.lat = coords[0]
                    i.lng = coords[1]
                    return i
                })

                var cc = dados.map(
                    i => `${this.arendondar(i.lat, 6)},${this.arendondar(i.lng, 6)}`
                )
                var listaCC = []

                cc.forEach(i => {
                    let x = i.split(',')
                    if (x.length === 2) {
                        if (!isNaN(x[0]) && !isNaN(x[1])) {
                            listaCC.push(x)
                        }
                    }
                })

                var listaFinal = []
                var distancias = []
                var tam = listaCC.length

                if (tam > 1) {
                    var ccInicio = listaCC[0]
                    listaCC.splice(0, 1)
                    listaFinal.push(ccInicio)
                    while (listaCC.length > 1) {
                        var dMin = 1000000000
                        var pos = -1
                        for (let i = 0; i < listaCC.length; i++) {
                            var dx = this.calcularDistancia(
                                ccInicio[0],
                                ccInicio[1],
                                listaCC[i][0],
                                listaCC[i][1]
                            )
                            if (dx < dMin) {
                                pos = i
                                dMin = dx
                            }
                        }
                        listaFinal.push(listaCC[pos])
                        distancias.push(dMin)
                        ccInicio = listaCC[pos]
                        listaCC.splice(pos, 1)
                    }
                    var coordenadas = []
                    var tempos = []
                    var penultimo = []
                    for (let x = 0; x < listaFinal.length; x++) {
                        coordenadas.push(
                            `${this.arendondar(listaFinal[x][0], 6)},${this.arendondar(
                                listaFinal[x][1],
                                6
                            )}`
                        )
                        if (x === listaFinal.length - 1) {
                            penultimo = listaFinal[x]
                        }
                    }
                    var ultimo = []
                    coordenadas.push(
                        `${this.arendondar(listaCC[0][0], 6)},${this.arendondar(
                            listaCC[0][1],
                            6
                        )}`
                    )
                    ultimo = listaCC[0]
                    // eslint-disable-next-line no-unused-vars
                    var distFinal = 0
                    tempos.push('0 seg')
                    distancias.forEach(x => {
                        distFinal += x
                        tempos.push(this.tempo(x))
                    })
                    var disRest = this.calcularDistancia(
                        penultimo[0],
                        penultimo[1],
                        ultimo[0],
                        ultimo[1]
                    )
                    distFinal += disRest
                    tempos.push(this.tempo(disRest))
                    var set = 1
                    var calculoFinal = ''
                    distFinal += disRest
                    tempos.push(this.tempo(disRest))
                    var countTag = 0
                    const arrayTag = ['Ⓒ', 'ⓞ', 'ⓞ', 'ⓡ', 'ⓓ', 'ⓢ', 'Ⓖ', 'Ⓞ']
                    var sizeTag = arrayTag.length
                    for (let x = 1; x <= coordenadas.length; x++) {
                        if (x === 1) {
                            // eslint-disable-next-line no-irregular-whitespace
                            calculoFinal += `\nTotal: ${coordenadas.length}\n【Ｃｏｏｒｄｓ ＧＯ】\n\nSᴇᴛ ${set}`
                            set++
                        }
                        calculoFinal += `\n${arrayTag[countTag]} ${coordenadas[x - 1]} - ${tempos[x - 1]
                            }`
                        if (x % 3 === 0 && x !== coordenadas.length) {
                            calculoFinal += `\n\nSᴇᴛ  ${set}`
                            set++
                        }
                        countTag = countTag === sizeTag - 1 ? 0 : countTag + 1
                    }
                    if (coordenadas.length !== 0) {
                        var opt1 =
                            '\n\nRota gerada em: Coords GO\nhttps://coordsgo.com/'
                        calculoFinal += `${opt1}`
                    }
                    var dist = 'Distância:'

                    this.saida = `${calculoFinal}\n`
                }
                if (dados.length) {
                    this.content = `${dist} ${this.arendondar(
                        distFinal,
                        3
                    )} km ${calculoFinal}\n`
                }

                const resultsnew = await this.content.matchAll(
                    // eslint-disable-next-line no-useless-escape
                    /(-?\d+(?![:|\/]).\d+, ?-?\d+(?![:|\/]).\d+)(:blank:)?(.+?(\d+\w+)?(\d+ ?)(\w{3}))?/g
                )
                this.result = Array.from(resultsnew).map(i => {
                    return {
                        coord: i[1],
                        copy: false,
                        tempo: i[5] ? `${i[5]}${i[6]}` : null,
                        time: i[5] ? Number(this.convert(i[5], i[6])) : 0,
                        id: Math.random()
                    }
                })
                localStorage.setItem('_result_limpar', JSON.stringify(this.result))
            }
        }
    }
}
</script>

<style lang="scss">
.paste {
    height: 25px;
}

.title {
    h2 {
        display: flex;
        justify-content: space-between;
        color: #673bb7;
        margin: 10px 0;
        font-size: 23px;
        border-bottom: 1px solid #673bb7;
        font-weight: bold;
    }
}

ul.home {
    margin: 0;
    padding: 0;
}

li {
    list-style: none;
    line-height: 30px;
}

li a {
    color: #2c3e50;
    text-decoration: none;
}

.coord {
    width: 200px;
}

textarea {
    width: 100%;
    margin: 10px auto;
    border: 2px solid #673bb7;
    padding: 10px;
    box-sizing: border-box;
}

table {
    margin: 0 auto;
    width: 100%;
}

td {
    text-align: left;
}

td:nth-child(2) {
    width: 70px;
}

hr {
    border-bottom: 1px dashed #ccc;
    border-style: dashed;
    width: 300px;
}

.list-corpo {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

button {
    font-size: 18px;
    padding: 5px;
    margin: 0 5px;
}

.whats {
    margin-top: 4px;
}

.back {
    background-color: #ffc107;
}

.front {
    background-color: #673ab7;
}

.back,
.front {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 100%;
    height: 100%;
}

.back {
    transform: rotateY(180deg);
}

.back,
.front {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.back,
.flipper,
.front {
    transform-style: preserve-3d;
    border-radius: 5px;
}

.btn-content {
    margin: 0 auto;
}

.btn-container {
    flex: 1;
    margin: 0 auto 15px auto;
}

.btn {
    display: flex;
    height: 50px;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.text-tempo {
    background-color: #673bb7;
    color: #fff;
    font-size: 14px;
    padding: 4px;
}

.btn-down {
    background: #673bb7;
    color: #fff;
    text-decoration: none;
    height: 50px;
    flex: 1;
    border-radius: 5px;
}

.btn-container {
    flex: 1;
    margin: 0 3px 15px 3px;
}

.list {
    padding: 5px;
    box-sizing: border-box;
    text-align: initial;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.list small {
    font-size: 12px;
    font-weight: normal;
}

.date {
    font-size: 10px !important;
}

.list.active {
    background: rgb(103 59 183 / 21%);
}

.list-content {
    cursor: pointer;
    font-weight: normal;
    font-size: 13px;
}

.list-content .btn-down {
    width: 120px;
    height: 35px;
}

h2 {
    display: flex;
    justify-content: space-between;
    color: #673bb7;
}

.vue-ads-leading-loose {
    display: none;
}

.vue-ads-justify-end {
    justify-content: flex-start;
}

.vue-ads-bg-teal-500 {
    background-color: #673bb7;
}

.vue-ads-flex {
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    margin: 10px 0;
}

.vue-ads-flex-grow {
    flex-grow: 0;
    flex: 1;
    justify-content: space-between;
}

.yellow {
    background-color: #ffc107 !important;
}

.logo {
    background: #673bb7;
    width: 40px;
    height: 40px;
    font-size: 32px;
    color: #fff;
    margin-left: 15px;
}

.logo .c {
    position: relative;
    top: -12px;
    left: -4px;
}

h3 {
    font-size: 20px;
    font-weight: bold;
}

.btn-velocity {
    color: #fff;
    background: #673ab7;
    width: 130px !important;
    margin: 0 auto;
    text-align: center;
    height: 25px !important;
    font-size: 12px;
    margin-bottom: 20px;
    border-radius: 5px;
}
</style>
